import { render, staticRenderFns } from "./AnswerYesNoNP.vue?vue&type=template&id=24fd5c83&scoped=true&"
import script from "./AnswerYesNoNP.vue?vue&type=script&lang=ts&"
export * from "./AnswerYesNoNP.vue?vue&type=script&lang=ts&"
import style0 from "./AnswerYesNoNP.vue?vue&type=style&index=0&id=24fd5c83&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fd5c83",
  null
  
)

export default component.exports