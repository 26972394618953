



























import { Vue, Component, Prop } from "vue-property-decorator";
import { AuditAnswer as IAuditAnswer } from "@/models/entities/audit-survey.interface";

const AuditSurveyImage = () =>
  import(/* webpackChunkName: "AuditSurveyImage" */ "../AuditSurveyImage.vue");

@Component({
  components: {
    "audit-survey-image": AuditSurveyImage,
  },
})
export default class AnswerPhotoList extends Vue {
  @Prop({ required: false, default: null, type: Object })
  private list: IAuditAnswer;

  private selectedImage = "";
  private showImage = 0;

  private loadImageSelected(el: string) {
    this.selectedImage = el;
    this.showImage = 1;
  }

  private resetParameters() {
    this.selectedImage = "";
    this.showImage = 0;
  }
}
