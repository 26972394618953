
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AnswerYesNoNP extends Vue {
  @Prop({ required: false, default: null, type: String })
  private answerValue: string;

  private value = null;

  private mounted() {
    this.value = this.answerValue ? this.answerValue.toLowerCase() : null;
  }
}
